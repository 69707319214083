<template>
  <div>
    <StatisticsByQuiz />
  </div>
</template>

<script>
import StatisticsByQuiz from "../components/ReportCenter/QuizStatistics/StatisticsByQuiz.vue";
export default {
  components: {
    StatisticsByQuiz,
  },
};
</script>

<style></style>
