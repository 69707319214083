<template>
  <div>
    <page-loading v-if="detailLoading"></page-loading>
    <QuizHeader
      v-else
      :percentOfCorrectAnswer="percentOfCorrectAnswer"
      :quiz="quiz"
      @refresh="GET_QUIZ_DETAIL()"
    ></QuizHeader>
    <export-excell
      :checkedList="checkedList"
      :selectedAllItemWithoutPage="selectedAllItemWithoutPage"
      :name="`${checkedList.length} ${$t('users')}: ${$t('quizzes')}`"
      :fields="fields"
      :count="count"
      @getAll="GET_ALL"
    ></export-excell>
    <Table
      checkable
      :check-value="checkedList"
      @change:selectAllWithoutPage="SET_SELECT_ALL_WITHOUT_PAGE"
      @change:checkedList="(e) => (checkedList = e)"
      :hideHeader="inside"
      :count="count"
      :headers="headers"
      :searchPlaceholder="$t('searchStudent')"
      @change:search="SET_SEARCH"
      :loading="isLoading"
      :items="items"
      no-card
    >
      <template #fullname="{ item }">
        <router-link :to="`/quiz-statistics/${item.userId}`">
          <div>{{ item.fullname }}</div>
          <div>
            <small class="text-muted">
              <div>
                {{ $t("lastAction") }}: {{ formatter(item.lastActionDate) }}
              </div>
            </small>
          </div>
        </router-link>
      </template>
      <template #successRate="{ item }">
        <h6 :class="courseRateClass(item.successRate)">
          %{{ item.successRate }}
          <small
            >({{ item.quizAnswerCount }} {{ $t("question") }} /
            {{ item.correctCount }} {{ $t("correctAnswer") }} )</small
          >
        </h6>
      </template>
    </Table>
    <div v-if="!inside" class="d-flex justify-content-center">
      <BoostrapPagination
        @change="SET_PAGINATION"
        :page="page"
        :pages="pages"
      ></BoostrapPagination>
    </div>
  </div>
</template>

<script>
import QuizHeader from "../../QuizDetail/QuizHeader.vue";
import Table from "../../Tables/index.vue";
import { formatter } from "gurhan/helpers/Date";
import { Currency } from "gurhan/helpers/Currency";
import BoostrapPagination from "gurhan/vue/BootstrapPagination.vue";
import PageLoading from "../../Shared/PageLoading.vue";
import ExportExcell from "../ExportExcell.vue";
export default {
  components: {
    ExportExcell,
    BoostrapPagination,
    QuizHeader,
    Table,
    PageLoading,
  },
  props: {
    inside: {
      default: false,
      type: Boolean,
    },
    limit: {
      default: 10,
    },
  },
  data() {
    return {
      checkedList: [],
      selectedAllItemWithoutPage: false,
      fields: {},
      detailLoading: false,
      percentOfCorrectAnswer: 0,
      quiz: null,
      search: "",
      items: [],
      isLoading: true,
      count: 0,
      page: 1,
      headers: [
        {
          title: this.$t("user"),
          value: "fullname",
          col: 12,
          md: 4,
          lg: 4,
          xl: 4,
        },
        {
          title: this.$t("successRate"),
          value: "successRate",
          col: 12,
          md: 4,
          lg: 4,
          xl: 4,
        },
      ],
    };
  },
  computed: {
    pages() {
      return Math.ceil(this.count / 10);
    },
    getSettings() {
      return this.$store.state.User.settings;
    },
  },
  methods: {
    formatter,
    Currency,
    SET_SELECT_ALL_WITHOUT_PAGE(all) {
      this.selectedAllItemWithoutPage = all;
    },
    CREATE_EXCEL_FIELD() {
      this.fields = {
        [`${this.$t("name")}`]: {
          field: "fullname",
          callback: (value) => {
            return value;
          },
        },
        [`${this.$t("successRate")}`]: {
          field: "successRate",
          callback: (value) => {
            return value;
          },
        },
        [`${this.$t("lastAction")}`]: {
          field: "lastActionDate",
          callback: (value) => {
            return formatter(new Date(value));
          },
        },
      };
    },
    async GET_ALL(response, fromCache) {
      const responsed = await this.$api.get(
        `Quizzes/QuizStatisticsByUser?quizId=${this.$route.params.id}&search=${this.search}&page=1&limit=${this.count}`
      );
      if (responsed.message === "OK") {
        this.checkedList = responsed.data.items;
        response(responsed.data.items, fromCache);
      }
    },
    toHHMMSS(secs) {
      var sec_num = parseInt(secs, 10);
      var hours = Math.floor(sec_num / 3600);
      var minutes = Math.floor(sec_num / 60) % 60;
      var seconds = sec_num % 60;

      return [hours, minutes, seconds]
        .map((v) => (v < 10 ? "0" + v : v))
        .filter((v, i) => v !== "00" || i > 0)
        .join(":");
    },
    courseRateClass(rate) {
      if (rate > 85) return "text-success";
      else if (rate > 70) return "text-info";
      else if (rate > 50) return "text-warning";
      else return "text-danger";
    },
    SET_PAGINATION(page) {
      this.page = page;
      this.$router.replace({ query: { page } });
      this.GET_ITEMS();
    },
    SET_SEARCH(search) {
      this.search = search;
      this.page = 1;
      this.$router.replace({ query: { search, page: 1 } });
      this.GET_ITEMS();
    },
    async GET_QUIZ_DETAIL() {
      this.detailLoading = true;
      const response = await this.$api.get(
        `Quizzes/Details/${this.$route.params.id}`
      );
      this.quiz = response.data;
      const total =
        this.quiz.correctQuestionTotalCount + this.quiz.wrongQuestionTotalCount;
      this.percentOfCorrectAnswer =
        total == 0
          ? 0
          : Math.ceil((this.quiz.correctQuestionTotalCount * 100) / total);
      this.detailLoading = false;
    },
    async GET_ITEMS() {
      this.isLoading = true;
      const response = await this.$api.get(
        `Quizzes/QuizStatisticsByUser?quizId=${this.$route.params.id}&search=${this.search}&page=${this.page}&limit=${this.limit}`
      );
      if (response.message === "OK") {
        this.items = response.data.items;
        this.count = response.data.count;
      }
      this.isLoading = false;
    },
  },
  mounted() {
    this.CREATE_EXCEL_FIELD();
    const q = this.$route.query;
    if (!isNaN(Number(q.page))) this.page = Number(q.page);
    this.GET_ITEMS();
    this.GET_QUIZ_DETAIL();
  },
};
</script>

<style></style>
